<template>
        <seccion-datos :titulo="seguridad" v-if="(id > 0)" tipo="danger" @cambios="guardar()">
            <h4 class="col-xs-12 lead">{{ $t('general.2fa') }}</h4>
            <div class="row">
                <div class="col-xs-12">
                    <div class="form-group">
                        <label for="tipo_2fa">{{ $t('general.tipo') }}</label>
                        <select v-model="tipo2fa"  class="custom-select form-control-sm select2" >
                            <option value="">--</option>
                            <option value="EMAIL">EMAIL</option>
                            <option value="SMS">SMS</option>
                        </select>
                    </div>
                </div>
                <div class="col-xs-6">
                    <div class="form-group">
                        <label for="email_2fa">Email</label>
                        <input type="email" class="form-control dato email_2fa" v-model="email2fa">
                    </div>
                </div>
                <div class="col-xs-6">
                    <div class="form-group">
                        <label for="movil_2fa">{{ $t('general.telefonomovil') }}</label>
                        <input class="form-control dato movil_2fa" v-model="movil2fa" id="movil_2fa" placeholder="Teléfono móvil" >
                    </div>
                </div> 
            </div>
            <h4 class="col-xs-12 lead">{{ $t('general.restriccionip') }}</h4>
            <div class="row">
                <div class="col-xs-12">
                    <div class="form-group">
                        <label for="ips_2fa">{{ $t('general.restriccionip2') }}</label>
                        <p class="text-yellow">{{ $t('general.restriccionip3') }}</p>
                        <input type="email" class="form-control dato ips_2fa" id="ips_2fa" v-model="ips2fa" placeholder="Lista de IP's públicas separadas por coma" >
                    </div>
                </div>
            </div>              
        </seccion-datos>
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
import $ from 'jquery';
export default {
    props: ['usuario'],
    data() {
        return {
            tipo2fa: '',
            email2fa: '',
            movil2fa: '',
            ips2fa: '',
            id: '',
            seguridad:'Seguridad',
        }
    },
    methods: {
        cargardatos() {
            this.$store.dispatch('cambiarCargando', true);
            this.tipo2fa = this.usuario.tipo_2fa;
            this.email2fa = this.usuario.email_2fa;
            this.movil2fa = this.usuario.movil_2fa;
            this.ips2fa = this.usuario.ips_2fa;
            this.id = this.usuario.id;
            this.$store.dispatch('cambiarCargando', false);
            this.seguridad = this.$t('general.seguridad')
        
        
    },
        async guardar() {
            this.$store.dispatch('cambiarGuardando', true);
            const api = new PwgsApi;
            const subidadatos = { tipo_2fa: this.tipo2fa, email_2fa: this.email2fa, movil_2fa: this.movil2fa, ips_2fa: this.ips2fa };
            try {
                await api.put('usuarios/' + this.id, subidadatos);
                //  this.$parent.obtenerUsuario(this.id);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                this.$store.dispatch('cambiarGuardando', false);
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'El trabajo no se puede modificar ya que está incluido en otro presupuesto generado', life: 5000 });
            }
        }, 
    },
    watch: {
        usuario() {
            this.cargardatos();
        }
    },
    mounted() {
        $('.select2').select2({
            theme: 'bootstrap4'
        }); 
    },
}
</script>
<style>

</style>