<template>
<seccion-datos :titulo="acceso" @cambios="guardar(this.id)">
            <div class="form-group">
                    <label for="usuario">{{ $t('general.usuario') }}</label>
                    <input type="text" class="form-control usuario dato" v-model="login" id="usuario" placeholder="Usuario acceso">
                </div>
                <div class="form-group">
                    <label for="clave">{{ $t('general.clave') }}</label>
                    <p class="text-yellow">{{ $t('general.contrasena') }}</p>
                    <input type="password" class="form-control clave" id="clave" v-model="clave" placeholder="Introduzca nueva contraseña si desea modificarla">
                </div>
                <div v-if="(id < 1)" class="form-group">
                    <label for="nombre_completo">{{ $t('general.nombrecompleto') }}</label>
                    <input type="text" class="form-control nombre_completo dato" v-model='nombre' id="nombre_completo"
                        placeholder="Nombre completo">
                </div>
                <div v-if="(id < 1)"  class="form-group">
                    <label for="nif">NIF/NIE</label>
                    <input type="text" class="form-control nif dato" v-model='nif' id="nif" placeholder="NIF/NIE">
                </div>
                <div v-if="(id < 1)"  class="form-group">
                    <label for="nif">{{ $t('general.direcciondeemail') }}</label>
                    <input type="text" class="form-control email dato" v-model='email' id="email"
                        placeholder="e-mail personal, usado para recuperación contraseña" autocomplete=false>
                </div>
</seccion-datos>
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
export default {
    props: ['usuario','id'],
    data() {
        return {
            login: '',
            clave: '',
            nif: '',
            nombre: '',
            email: '',
            acceso:'Acceso'
        }
    },
    methods: {
        cargardatos() {
            this.$store.dispatch('cambiarCargando', true);
            console.log('usuario', this.usuario);
            this.login = this.usuario.nombre;
            this.clave = this.usuario.contraseña;  
            this.$store.dispatch('cambiarCargando', false);
            this.acceso = this.$t('general.acceso');
        },
        async guardar(id) {
            const api = new PwgsApi;
            this.$store.dispatch('cambiarGuardando', true);
            if (id > 0) {
                /* put  modulos/pwgsapi/index.php/usuarios/:id */
                const subidadatos = { nombre: this.login};
                if(this.clave!=''){
                    subidadatos.contraseña = this.clave;
                }
                try {
                    await api.put('usuarios/' + id, subidadatos);
                    this.$parent.$parent.obtenerUsuario(this.id);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                }
                 catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: 'El trabajo no se puede modificar ya que está incluido en otro presupuesto generado', life: 5000 });
                }
                //this.cargardatos();
            }
            else {  
                /* post  modulos/pwgsapi/index.php/usuarios */
                try {
                    let res;
                    const subidadatos = { nombre: this.login, nombre_completo: this.nombre, nif: this.nif, email: this.email };
                    if(this.clave!=''){
                        subidadatos.contraseña = this.clave;
                    }
                    res = await api.post('usuarios/', subidadatos);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                    this.$router.push('/usuarios/' + res.id);
                }
                 catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: 'El trabajo no se puede modificar ya que está incluido en otro presupuesto generado', life: 5000 });
                }
            }
            this.$store.dispatch('cambiarGuardando', false);
        },
    },
    watch: {
        usuario() {
            this.cargardatos();
        }
    },
}
</script>