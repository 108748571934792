<template>
     <div class="row">
         <div class="col-md-4">
             <usuario-acceso-login :usuario="usuario" :id="id" /> 
         </div>
         <div class="col-md-8">
             <usuario-acceso-seguridad :usuario="usuario" :id="id" />
         </div>
     </div>
</template>

<script>
import UsuarioAccesoLogin from './secciones/UsuarioAccesoLogin.vue'
import UsuarioAccesoSeguridad from './secciones/UsuarioAccesoSeguridad.vue'

export default({
    components: {
        'usuario-acceso-login': UsuarioAccesoLogin,
        'usuario-acceso-seguridad': UsuarioAccesoSeguridad,
    }
})
</script>